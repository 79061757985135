<template>
    <ion-page data-pageid="canhan">
        <ion-header>
            <ion-toolbar style="--background: #03a9f4">
                <ion-row
                    class="ion-align-items-center ion-justify-content-start pl-3"
                    style="height: 100px"
                >
                    <div class="anh-dai-dien-style pl-3"></div>
                    <div class="font-20 font-bold white--text pl-2">
                        {{
                            `${
                                $Core.AuthApi.TokenParsed
                                    ? $Core.AuthApi.TokenParsed.family_name || ""
                                    : ""
                            } ${
                                $Core.AuthApi.TokenParsed
                                    ? $Core.AuthApi.TokenParsed.given_name || ""
                                    : ""
                            }`
                        }}
                    </div>
                </ion-row>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item
                    v-for="(item, index) in danhSachChucNang"
                    :key="index"
                    :router-link="item.link"
                    detail
                    button
                >
                    <ion-label>{{ item.text }}</ion-label>
                </ion-item>
                <ion-item
                    router-link="/huong-dan-su-dung"
                    v-if="$Core.AuthApi.TokenParsed.preferred_username != '0888888888'"
                >
                    <ion-label>Hướng dẫn sử dụng phần mềm</ion-label>
                    <!-- <DxButton
                        type="normal"
                        hint="Hướng dẫn"
                        styling-mode="text"
                        icon="mdi mdi-help-circle-outline"
                        class=""
                    /> -->
                </ion-item>
            </ion-list>

            <div class="px-3 py-2 mt-2">
                <ion-button
                    v-on:click="dangXuat"
                    color="danger"
                    fill="outline"
                    expand="block"
                >
                    Đăng xuất
                </ion-button>
            </div>
            <div class="px-3" v-if="!isDelete">
                <ion-button
                    v-on:click="thucHienKhoaTaiKhoan"
                    color="danger"
                    expand="block"
                >
                    Xóa tài khoản
                </ion-button>
            </div>
            <div class="px-3" v-else>
                <ion-button
                    v-on:click="thucHienKhoiPhucTaiKhoan"
                    color="success"
                    expand="block"
                >
                    Khôi phục tài khoản
                </ion-button>
            </div>
            <!-- Xoa tai khoan -->
            <PopupVue
                height="260px"
                v-model:dialog="dialogThongBaoXoaTaiKhoan"
                title=""
                :fullWidth="false"
                @close="closeThongBaoXoaTaiKhoan"
                classPopup="popup-padding-16 popup-padding-bottom-8"
            >
                <template #content
                    ><ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
            <!-- Khoi phuc tai khoan -->
            <PopupVue
                height="260px"
                v-model:dialog="dialogThongBaoKhoiPhucTaiKhoan"
                title=""
                :fullWidth="false"
                @close="closeThongBaoKhoiPhucTaiKhoan"
                classPopup="popup-padding-16 popup-padding-bottom-8"
            >
                <template #content
                    ><ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
            <DxToast
                v-model:visible="toast.visible"
                v-model:type="toast.type"
                :position="{
                    my: 'top center',
                    at: 'top center',
                    offset: '0 15',
                }"
                class="toast-style"
            >
                <template #contentTemplate>
                    <ToastContentVue :toast="toast" />
                </template>
            </DxToast>
            <div class="notify-unread" v-if="isDelete">
                <span class="notify-unread-text"
                    >Tài khoản sẽ bị xóa sau {{ soNgayConLai }} ngày!</span
                >
            </div>

            <Devtool />
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../components/_Common/ThanhTieuDe";
import PopupVue from "@/components/_Common/Popup";
import ThongBaoVue from "@/components/_Common/ThongBao";
import ToastContentVue from "@/components/_Common/ToastContent";
import { DxButton } from "devextreme-vue/button";
import {
    IonButton,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonItem,
    IonList,
    IonLabel,
    IonRow,
} from "@ionic/vue";
import { DxToast } from "devextreme-vue";
import Devtool from "../../components/_Common/Devtool.vue";
export default {
    components: {
        ThanhTieuDeVue,
        IonButton,
        IonToolbar,
        IonHeader,
        IonContent,
        IonPage,
        IonItem,
        IonList,
        IonLabel,
        IonRow,
        PopupVue,
        ThongBaoVue,
        ToastContentVue,
        DxToast,
        DxButton,
        Devtool,
    },
    props: {},
    data() {
        return {
            danhSachChucNang: [
                {
                    id: 1,
                    text: "Thông tin cá nhân",
                    link: "/Ca-Nhan/thong-tin-ca-nhan",
                },

                // {
                //     id: 2,
                //     text: "Thông báo",
                //     link: "/thong-bao",
                // },
                // {
                //     id: 3,
                //     text: "Lịch sử bán vé",
                //     link: "/Bao-Cao/lich-su-ban-ve",
                // },
                // {
                //     id: 4,
                //     text: "Danh sách phương tiện đã vận hành",
                //     link: "/danh-sach-phuong-tien-da-van-hanh",
                // },
                // {
                //     id: 5,
                //     text: "Cài đặt",
                //     link: "",
                // },
            ],
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            dialogThongBaoXoaTaiKhoan: false,
            dialogThongBaoKhoiPhucTaiKhoan: false,
            toast: { visible: false, message: "", type: "success" },
            isDelete: false,
            ngayKhoa: null,
            soNgayConLai: 30,
        };
    },
    methods: {
        dangXuat() {
            console.log(
                "%c  ",
                "color: white; background-color: #95B46A",
                "=========>",
                $Core.AuthApi,
            );
            // this.emitter.emit("loadding", true);
            // this.$store.commit("TaiKhoan/Set", {
            //     key: "DangXuat",
            //     data: true,
            // });
            // this.$router.push("/Dang-Nhap");
            this.$Core.AuthApi.Logout();
        },
        thucHienKhoaTaiKhoan() {
            this.ParamThongBao = {
                state: "Warning",
                title: "Xác nhận xóa tài khoản",
                message: "Bạn có chắc chắn muốn xóa tài khoản này?",
            };
            this.dialogThongBaoXoaTaiKhoan = true;
        },
        async closeThongBaoXoaTaiKhoan(param) {
            this.dialogThongBaoXoaTaiKhoan = false;
            if (!param) {
                return;
            }
            await this.XoaTaiKhoan();
        },
        async XoaTaiKhoan() {
            try {
                let result = await this.$store.dispatch("TaiKhoan/XoaTaiKhoan", {
                    tenTaiKhoan: $Core.AuthApi.TokenParsed
                        ? $Core.AuthApi.TokenParsed.preferred_username
                        : "",
                });

                if (result.errorCode == 0) {
                    this.toastThanhCong(
                        "Xóa tài khoản thành công. Tài khoản sẽ được xóa trong vòng 30 ngày!",
                    );
                    this.LayThongTinTaiKhoan();
                } else {
                    this.toastThatBai(result.message);
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.toastThatBai("Xóa tài khoản thất bại!");
            }
        },
        thucHienKhoiPhucTaiKhoan() {
            this.ParamThongBao = {
                state: "Warning",
                title: "Xác nhận khôi phục tài khoản",
                message: "Bạn có chắc chắn muốn khôi phục tài khoản này?",
            };
            this.dialogThongBaoKhoiPhucTaiKhoan = true;
        },
        async closeThongBaoKhoiPhucTaiKhoan(param) {
            this.dialogThongBaoKhoiPhucTaiKhoan = false;
            if (!param) {
                return;
            }
            await this.KhoiPhucTaiKhoan();
        },
        async KhoiPhucTaiKhoan() {
            try {
                let result = await this.$store.dispatch("TaiKhoan/KhoiPhucTaiKhoan", {
                    tenTaiKhoan: $Core.AuthApi.TokenParsed
                        ? $Core.AuthApi.TokenParsed.preferred_username
                        : "",
                });

                if (result.errorCode == 0) {
                    this.toastThanhCong("Khôi phục tài khoản thành công!");
                    this.LayThongTinTaiKhoan();
                } else {
                    this.toastThatBai(result.message);
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.toastThatBai("Khôi phục tài khoản thất bại!");
            }
        },
        closeAllDialog() {
            this.dialogThongBaoXoaTaiKhoan = false;
            this.dialogThongBaoKhoiPhucTaiKhoan = false;
        },
        toastThanhCong(message) {
            this.toast = {
                message: message,
                type: "success",
                visible: true,
            };
        },
        toastThatBai(message) {
            this.toast = {
                message: message,
                type: "error",
                visible: true,
            };
        },
        async LayThongTinTaiKhoan() {
            let rs = await this.$store.dispatch("TaiKhoan/ThongTinTaiKhoan", {
                tenTaiKhoan: $Core.AuthApi.TokenParsed
                    ? $Core.AuthApi.TokenParsed.preferred_username
                    : "",
            });
            if (rs.errorCode == 0) {
                this.isDelete = rs.data.IsDelete;
                this.ngayKhoa = rs.data.NgayKhoa;
                this.LayNgayTaiKhoanBiXoa();
            }
        },
        LayNgayTaiKhoanBiXoa() {
            let ngayTaiKhoanBiXoaHoanToan = new Date(this.ngayKhoa);
            ngayTaiKhoanBiXoaHoanToan = new Date().setDate(
                ngayTaiKhoanBiXoaHoanToan.getDate() + 30,
            );
            // 1 ngay bang 86400000
            let miliGiay1ngay = 86400000;
            let soNgayConLai =
                (ngayTaiKhoanBiXoaHoanToan - Date.parse(this.$Helper.getToday())) /
                miliGiay1ngay;

            this.soNgayConLai = Math.ceil(soNgayConLai - 1);
        },
        MoTrangHuongDan() {
            window.open(
                this.$i18n.t("UrlHuongDan.RootUrl") +
                    this.$i18n.t("UrlHuongDan.UyThacBanVe"),
                "_blank",
            );
        },
    },
    created() {
        this.LayThongTinTaiKhoan();
    },
    mounted() {},
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.anh-dai-dien-style {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $border;
}
.item-style {
    border-bottom: 1px solid $border;
    color: $text;
}
.item-style:active {
    background-color: $border;
}
</style>

<style scoped>
.notify-unread {
    position: fixed;
    right: 16px;
    bottom: 16px;
    background-color: rgb(251 140 0 / 80%);
    padding: 8px;
    box-shadow: 0 2px 9px #dadce0;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: 600;
    /* transition: all 0.2s ease; */
    width: 224px;
    overflow: hidden;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .notify-unread {
        bottom: 48px;
    }
}
</style>
